._card_response .card{
   border-radius: 8px !important;
   border: none;
   box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
}
._header-question .col-10, ._header-question .col-2{
    display:flex;
    align-items: center;
    justify-content: center;
}
._header-question h5{
    font-family: 'PlayfairDisplay-bold';
}

._header-question .btb-collap{
    background-color: #FFA02C;
    width: 45px;;
    height: 45px;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
}
._header-question .btb-collap svg{
    font-size: 30px;
    color: #fff;
}