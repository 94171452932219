._menu_header{
    background-color: #f8f9fa;
    height: 75px;
    width: 100%;
}
._menu_header .container{
    margin-top: 10px;
}
.dropdown-menu a{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 35px;
    width: 80%;
    padding: 0px;
    margin: 0px auto;
    font-weight: bold;
}
.dropdown-menu a:hover{
   color: #fff;
   background-color: #5C89FF;
}

.navbar-nav .nav-link{
    margin: 0 8px;
    font-weight: bold;
}

._btn_reg a{
    background-color: #FFA02C;
    padding: 8px 10px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    margin-left: 20px;
    transition: .1s;
}
._btn_reg a:hover{
    background-color: #FCB155;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, .3);
}

._img_header img{
    width: 60px;
}

.navbar-toggler{
    border: none;
}
.navbar-toggler:focus{
    box-shadow: none;
    outline: none;
}

@media (min-width: 320px) and (max-width: 480px) {
    ._btn_reg a{
        margin-left: 0px;
    }
    ._img_header img{
        width: 50px;
    }
}

