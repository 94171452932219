._categories{
    padding: 20px 0px;  
     
}


@media (min-width: 320px) and (max-width: 480px) {
    ._categories{
        padding: 0 0 20px 0;   
    }  
}