

._presentation ._icon{
    font-size: calc(3rem + 2vh);
    color: #00BBE6;
}
._presentation h3{
    font-family: 'PlayfairDisplay-bold'
}
._presentation p{
    text-align: center;
}
._presentation img{
    width: 180px;
}
@media (max-width: 1025px){
    ._presentation .col-md-3:nth-child(4){
        display: none;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    ._presentation .col-md-3{
        margin-top: 25px;
    }
    ._presentation p{
        text-align: center;
        width: 80%;
        margin:  0 auto;
    } 
}
