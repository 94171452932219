._Offers{
    background-color: #f1f1f1;
    padding: 100px 0;
}
._Offers h1{
    font-family: 'PlayfairDisplay-black';
    font-size: calc(2.2rem + 2.2vh);
}
._card_new a{
    text-decoration: none;
}
._card_new{
    display: flex;
    align-items: center;
    justify-content: center;
}
._card_new:hover ._card_register{
    background-color: #fff;
    box-shadow: 2px 2px 4px rgba(0,0,0, .3);
}
._card_register{
    /* background: indianred; */
    min-height: 230px;
    border-radius: 8px;
    width: 100%;
    padding: 15px;
    transition: .3s;
}
.__icon{
    width: 70px;
    height: 70px;
    background:  #FFB55A;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    box-shadow: 2px 2px 4px rgba(0,0,0, .3);
}
.__icon:before{
    display: block;
    content: "";
    width: 0px;
    height: 0px;
    background: #FFA02C;
    position: absolute;
    border-radius: 50%;
    z-index: 10;
    transition: .3s;
}
.__icon:hover::before{
    width: 100%;
    height: 100%;
}
.__icon svg{
    font-size: 50px;
    color: #fff;
    z-index: 20;
}
._card_register p{
    font-size: calc(.7rem + .7vh);
    color: #222;
}
._card_register h3{
    color: #FFA02C;
}

@media (min-width: 320px) and (max-width: 480px) {
    ._Offers{
    padding: 150px 0;
   }
}
