.tag_category a{
    text-decoration: none;
    color: #222;
}
._card_category{
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0,0, .2);
    border-radius: 8px;
    text-align: center;
    padding: 10px 0;
    border-bottom: 8px solid;
    font-family: 'PlayfairDisplay-bold';
}