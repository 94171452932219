._Faq{
    margin-top: 80px;
}

._Faq ._tit h1{
    margin-top: 40px;
    font-family: 'PlayfairDisplay-black';
    font-size: calc(2.7rem + 2.7vh);
}
._Faq ._tit h3{
    font-family: 'PlayfairDisplay-bold';
    font-size: calc(1.5rem + 1.5vh);
    margin-bottom: 20px;
}
._questions{
    background: #D6F6FC;
    padding: 80px 0;
    padding-bottom: 160px;
}
._questions h2{
    font-family: 'PlayfairDisplay-bold';
}
@media (min-width: 320px) and (max-width: 480px){
    ._questions{
        padding-bottom: 180px;
    }
}