
._cont_input{
    padding-top: 70px;
background-color: #D6F6FC;
}
._Search #wave{
    margin-top: -10px;
    width: 100%;
    fill: #D6F6FC;
}
._Search ._font{
    
    color: #222222;
}
._font h1{
    font-family: 'PlayfairDisplay-black';
    font-weight: bold;
    letter-spacing: 1px;
    font-size: calc(1.8rem + 1.8vh);
}
._font h3{
    font-family: 'PlayfairDisplay-bold';
}

._Search .input_search{
    position: relative;
    margin-top: 20px;
    width: 80%;
    height: 75px;
    box-shadow: 0px 0px 4px rgba(0, 0,0, .3);
    border-radius: 8px;
    transform: translateY(40px);
}
._Search .input_search input{
    position: absolute;
    height: 100%;
    width: 85%;
    border-radius: 8px 0 0 8px;
    border:none;
    font-size: calc(1rem + 1vh);
    padding: 0px 10px;
}
._Search .input_search input:focus{
    outline: none;
}
._Search .input_search button{
    position: absolute;
    right: 0px;
    width: 15%;
    height: 100%;
    border-radius: 0 8px 8px 0;
    border:none;
    background-color: #FFA02C;
    color: #fff;
    font-size: 30px;
}

@media (min-width: 320px) and (max-width: 480px) {
    
    ._font h1{
        font-weight: bold;
        letter-spacing: 1px;
        font-size: calc(1.1rem + 1.1vh);
    }
    ._font h3{
        font-family: 'PlayfairDisplay-bold';
        font-size: calc(.7rem + .7vh);
    }
    ._Search .input_search{
        width: 100%;
        height: 50px;
        transform: translateY(0px);
    }
    ._Search .input_search input{
        font-size: calc(.8rem + .8vh);
    }
    
  }