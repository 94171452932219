@font-face{
  font-family: 'PlayfairDisplay-black';
  src: url('./assets/fonts/PlayfairDisplay/PlayfairDisplay-Black.ttf');
}
@font-face{
  font-family: 'PlayfairDisplay-bold';
  src: url('./assets/fonts/PlayfairDisplay/PlayfairDisplay-Bold.ttf');
}
@font-face{
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf');
}

body {
  margin: 0;
  font-family: 'Roboto-Regular'  !important;
  letter-spacing: 0.5px;
}
