._Banner{
    margin-top: 70px;
}
._Banner .carousel-indicators{
    transform: translateY(-10px);
}
._Banner .carousel-indicators li{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
._Banner .carousel-indicators .active{
    background-color: #fff;
}

._line_banner{
   height: 25px;
}


@media (min-width: 320px) and (max-width: 480px) {
    ._Banner .carousel-indicators{
        transform: translateY(8px);
    }
    ._Banner .carousel-indicators li{
        width: 12px;
        height: 12px;
    }
    ._line_banner{
        height: 10px;
     }
    
  }