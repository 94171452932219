._Form_contact{
    width: 100%;
}
._Form_contact h3{
    font-family: 'PlayfairDisplay-bold';
    margin-top: 80px;
}
._Form_contact input{
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border:none;
    padding: 0px 10px ;
    box-shadow: 2px 2px 4px rgba(0, 0,0, .2);
    margin-bottom: 20px;
}
._Form_contact form{
    margin-top: 60px;
    text-align: center;
}
._Form_contact form p{
    text-align: left;
}
.double_input{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
}
._Form_contact button{
    border-radius: 8px;
    padding: 14px 20px;
    background-color: #FFA02C;
    border:none;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 2px 2px 4px rgba(0, 0,0, .2);
    margin-top: 30px;
}

._message_success{
    height: 30px;
}
._message_success p{
    color: teal;
    animation: fade 1s;
}
._warning{
    color: red;
    animation: warn .3s infinite alternate;
}

@keyframes fade{
    0%{ opacity: 0;
    }100%{ opacity: 1;}
}
@keyframes warn{
    0%{
      transform: translateX(-3px);
    }100%{
       transform: translateX(3px);
    }
}