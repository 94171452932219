
#wave_f{
    fill: #D6F6FC;
    width: 100%;
}
#wave_f_m{
    fill: #D6F6FC;
    width: 100%;
    display:none;
}
.content-footer{
    background-color: #D6F6FC;
    padding: 30px 0;
}
.content-footer .col-xl-3:nth-child(1),.content-footer .col-xl-3:nth-child(4) {
    display: flex;
    align-items: center;
    justify-content: center;
}
.content-footer h4{
    font-family: 'PlayfairDisplay-bold';
}
.content-footer ul{
    list-style: none;
    padding: 0px;
}
.content-footer ul a{
    text-decoration: none;
    color: #222222;
}
.content-footer .col-xl-3:nth-child(2) li{
    margin-top: 10px;
}
.content-footer .col-xl-3:nth-child(2) li:last-child{
    margin-top: 30px;
}
.content-footer  .social_net a:first-child  div{
    margin-left: 0px; 
}
.content-footer  .social_net a div{
 background-color: #00BBE6;
 margin-left: 8px;
 width: 35px;
 height: 35px;
 display: inline-block;
 border-radius: 50%;
text-align: center;
padding-top: 5px;
transition: .1s;
}
.content-footer  .social_net a svg{
    color: #fff;
    font-size: 18px;
}
.content-footer  .social_net a div:hover{
    transform: scale(1.08);
}
._Footer ._info_p a{
   text-decoration: none;
   color: #222222;
   font-size: 13px;
}
@media (max-width: 700px){
    #wave_f{
        fill: #D6F6FC;
        width: 100%;
        display:none;
    }
    #wave_f_m{
        fill: #D6F6FC;
        width: 100%;
        display:block;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .content-footer .col-xl-3:nth-child(2) li:last-child{
        margin-top: 5px;
    }
    
  }