.card_vertical{ padding-left: 0px;}
.img-vertical{ padding-right: 0px;}

.card_part_2{
    height: 310px;
    min-height: 300px;
    padding: 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
}
.img-vertical img{
    height: 310px;
    min-height: 300px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
}

.img-vertical.round img{
    border-radius: 8px;
}
.chips.g{
    padding-right: 15px;
}
.chips.g div:first-child{
    border-bottom-left-radius: 0px;
}

@media (max-width: 576px) {
    .card_vertical{ padding-left: 15px;}
    .img-vertical{ padding-right: 15px;}
    .img-vertical img{
        height: 250px;
        min-height: 260px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 8px;
    }
    .card_part_2{
        height: 250px;
        min-height: 260px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 8px;
    }
    .chips.g div:first-child{
        margin-left: 15px;
    }
    .img-vertical.round{
        min-height: 500px;
    }
    .img-vertical.round img{
        min-height: 500px;
    }
  }