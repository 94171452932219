._subscription{
    padding:40px 0 20px 0;
}
._input_sub{
    position: relative;
    height: 75px;
    width: 80%;
    margin-top: 20px;
    float: right;
    background: tomato;
    box-shadow: 0px 0px 4px rgba(0, 0,0, .3);
    border-radius: 8px;
}
._input_sub input{
    position: absolute;
    height: 100%;
    width: 85%;
    border-radius: 8px 0 0 8px;
    border:none;
    font-size: calc(1rem + 1vh);
    padding: 0px 10px;
}
._input_sub input:focus{
    outline: none;
}
._input_sub button{
    position: absolute;
    right: 0px;
    width: 15%;
    height: 100%;
    border-radius: 0 8px 8px 0;
    border:none;
    background-color: #FFA02C;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}

@media (min-width: 320px) and (max-width: 480px) {
    ._input_sub{
        width: 100%;
        height: 50px;
        transform: translateY(0px);
    }
    ._input_sub input{
        font-size: calc(.8rem + .8vh);
    }
}