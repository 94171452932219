._Contact{
    background-color: #D6F6FC;
    min-height: 100vh;
}
._Contact .row{
    padding-top: 80px;
    min-height: 100vh;
}

.contact_text{
    width: 100%;
}
.contact_text h1{
    font-family: 'PlayfairDisplay-bold';
    font-size: calc(2rem + 2vh);
    margin-bottom: 70px;
    margin-top: 80px;
}
.contact_text ul{
    list-style: none;
    padding:0px;
    margin-top: 35px;
}
.contact_text li a{
  text-decoration: none;
  color: #222;
}
.contact_text li:last-child{
    margin-top: 20px;
}
.contact_text li:last-child div{
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #00BBE6;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    padding-top: 8px;
    margin-right: 10px;
}
.contact_text p{
    margin-top:50px;
    font-family: 'PlayfairDisplay-bold';
    color: #FFA02C;
    line-height: 26px;
}
.contact_text p span{
    font-weight: bold;
    font-size: 30px;
    font-family: 'Roboto-Regular';
    color: #222;
}
.contact_text p i{
    font-family: 'Roboto-Regular';
    color: #222;
}
.c_jor a{
  text-decoration: none;
  color: #222;
}

