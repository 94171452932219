._Card{
    min-height: 230px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
    border-radius: 8px;
    padding: 10px;
    position: relative;
}
.chips{
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: #828282;
}
.chips div{
    text-align: center;
    font-size: calc(.4rem + .5vh);
    background: #f1f1f1;
    padding: 3px 0;
    padding: 2px 0;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}
.chips div:first-child{
    font-weight: bold;
    border-bottom-left-radius: 8px;
    color: #fff;
    padding-left: 2px;
}
.chips div:last-child{
    border-bottom-right-radius: 8px;
}

._header_info .col-7{
    display: flex;
    align-items: center;
    justify-content: center;
}
._header_info .h4{
    font-family: 'PlayfairDisplay-black';
    padding: 0 10px;
    height: 60px;
}
._header_info .h4 h4{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
._Card .info_contact{
  margin: 20px 0 30px 0;
}

.info_contact ul{
    list-style: none;
    font-size: 13px;
    padding: 0px;
    width: 80%;
    margin:0 auto;
}
.info_contact svg{
    margin-right: 6px;
    font-size: 14px;
    color: indianred;
}
.info_contact li a{
    text-decoration: none;
}
.info_contact li{
    line-height: 15px;
    margin-top: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.info_contact li:last-child{
    height: 35px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@media (min-width: 320px) and (max-width: 480px){
    .chips div{
        font-size: calc(.5rem + .5vh);
    }
}